<template>
  <div class="bg-gray-200">
    <div class="container mx-auto grid grid-cols-2 w-full">
      <div class="py-2 text-md">
        <router-link to="/" class="text-green hover:underline">Dashboard</router-link>
        <span class="text-gray-400"> / </span>
        <router-link to="/commissions" class="text-green hover:underline">Commissions</router-link>
        <span class="text-gray-400"> / </span>
        <span class="font-bold">Paid Commission {{ parseDate(paidCommission.created_at) }}</span>
      </div>
    </div>
  </div>
  <div class="container mx-auto py-5" v-if="!loadingPaidCommission">
    <div class="grid grid-cols-3 xl:grid-cols-4">
      <div class="col-span-3 grid grid-cols-3">
        <div class="shadow col-span-1 bg-gray-50 text-center px-4 pt-2 pb-10">
          <div class="text-xs flex text-gray-400">
            <span >Created {{ parseDate(paidCommission.created_at) }}</span>
            <span class="text-right flex-grow">Updated {{ parseDate(paidCommission.updated_at) }}</span>
          </div>
          <span class="block text-gray-400 mt-10">Commission Receipt on</span>
          <h2 class="font-bold text-3xl">{{ parseDate(paidCommission.created_at) }}</h2>
        </div>
        <div class="shadow ml-5 bg-gray-100 col-span-2">
          <div class="text-sm">
            <div class="px-5 py-2 flex bg-gray-50">
              <span class="w-4/12">Rep Company</span>
              <span class="w-8/12 font-bold">{{ user.company.name }}</span>
            </div>
            <div class="px-5 py-2 flex bg-gray-50">
              <span class="w-4/12">Total Shipping Rep Commissions</span>
              <span class="w-8/12">${{ totalShippingRepCommissions.toFixed(2) }}</span>
            </div>
            <div class="px-5 py-2 flex bg-gray-50">
              <span class="w-4/12">Total Billing Rep Commissions</span>
              <span class="w-8/12">${{ totalBillingRepCommissions.toFixed(2) }}</span>
            </div>
            <div class="px-5 py-2 flex bg-gray-50">
              <span class="w-4/12">Total Part Owner Commissions</span>
              <span class="w-8/12">${{ totalPartOwnerCommissions.toFixed(2) }}</span>
            </div>
            <div class="px-5 py-2 flex bg-gray-50">
              <span class="w-4/12 font-bold">Total Commissions Paid</span>
              <span class="w-8/12 font-bold">${{ totalCommissions.toFixed(2) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-6">
      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Part #</th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">MLE SO #</th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Customer (PO #)</th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Shipping Rep Commission</th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Billing Rep Commission</th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Part Owner Commission</th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total Commission</th>
                </tr>
                </thead>
                <tbody>
                <tr :class="line.id % 2 == 0 ? 'bg-white' : 'bg-gray-100'" v-for="line in paidCommission.lines" v-bind:key="line.id">
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"><span class="font-medium text-gray-900">{{ line.description}}</span> x {{ line.quantity }}</td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ line.sales_order.number }}</td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{{ line.sales_order.customer.company }} <span v-if="line.sales_order.customer_po_number">({{ line.sales_order.customer_po_number.trim() }})</span></td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">${{ line.shipping_rep_amount }}</td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">${{ line.billing_rep_amount }}</td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">${{ line.part_owner_amount }}</td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900 font-bold">${{ line.total_amount }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div><!-- container -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => {
    return {
      loadingPaidCommission: true,
      totalShippingRepCommissions: 0,
      totalBillingRepCommissions: 0,
      totalPartOwnerCommissions: 0,
      totalCommissions: 0,
    };
  },

  computed: {
    ...mapGetters({
      paidCommission: "paidCommissions/details",
      user: "user",
    }),
  },

  created: function () {
    document.title = "MLE Rep Center | Paid Commission";
    this.loadPaidCommission();
  },

  methods: {
    async loadPaidCommission() {
      this.loadingPaidCommission = true;
      await this.$store.dispatch(
        "paidCommissions/loadDetails",
        this.$route.params.id
      );
      this.loadingPaidCommission = false;
      for (var i = 0; i < this.paidCommission.lines.length; i++) {
        this.totalShippingRepCommissions += parseFloat(
          this.paidCommission.lines[i].shipping_rep_amount
        );
        this.totalBillingRepCommissions += parseFloat(
          this.paidCommission.lines[i].billing_rep_amount
        );
        this.totalPartOwnerCommissions += parseFloat(
          this.paidCommission.lines[i].part_owner_amount
        );
        this.totalCommissions += parseFloat(
          this.paidCommission.lines[i].total_amount
        );
      }
    },

    parseDate(date) {
      let d = new Date(date);
      return d.toLocaleDateString();
    },

    formatNumber(n) {
      return (Math.round(parseFloat(n) * 100) / 100).toLocaleString(undefined, {
        minimumFractionDigits: 2,
      });
    },
  },
};
</script>
